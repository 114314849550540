import React, { useState } from 'react'
import { Link } from 'gatsby'
import { useSiteState } from '../../lib/siteContext'

//Components
import LabelFilter from '../ui/labelFilter'
import Toggle from '../ui/toggle'

const PackagingFilter = ({
  filterFeatures,
  filterIndustries,
  filterCategories,
  greenMode,
  setGreenMode,
  assocProducts,
  setAssocProducts,
  filters,
  setFilters,
  setFiltersOpen,
  showFilterCta,
  ctaButtonText,
  ctaText,
  ctaForm,
}) => {
  const { setGlobalForm, setFormIsActive, formIsActive } = useSiteState()

  console.log('filters', filters)

  function filterExists(name, group) {
    return (
      filters.find((f) => f.name === name && f.group === group) !== undefined
    )
  }

  function addFilter(name, group, fnc) {
    setFilters((currentFilters) => [...currentFilters, { name, group, fnc }])
  }

  function removeFilter(name, group) {
    setFilters((currentFilters) =>
      currentFilters.filter((f) => !(f.name === name && f.group === group))
    )
  }

  function toggleFilter(name, group, fnc) {
    if (filterExists(name, group)) {
      removeFilter.apply(null, arguments)
    } else {
      addFilter.apply(null, arguments)
    }
  }

  return (
    <>
      <div className="w-full pb-6 flex justify-between lg:hidden">
        <span className="text-lg">Filters</span>
        <button
          className="text-base"
          onClick={() => {
            setFiltersOpen(false)
          }}
        >
          Close
        </button>
      </div>
      <ul className="filter-categories text-md text-pp-grey50 mb-6">
        <li className="col-span-full mb-1 text-sm">Categories</li>
        {filterCategories.edges.map((item) => {
          if (item.node.title !== 'Rigid Boxes') {
            return (
              <li>
                <LabelFilter
                  text={item.node.title}
                  active={filterExists(item.node.title, 'category')}
                  onClick={() =>
                    toggleFilter(item.node.title, 'category', (project) => {
                      var projectIsShown = false
                      project.categories.map((projectCategory) =>
                        projectCategory.title === item.node.title
                          ? (projectIsShown = true)
                          : (projectIsShown = false)
                      )
                      return projectIsShown
                    })
                  }
                />
              </li>
            )
          }
        })}
      </ul>
      <div className="grid grid-cols-2 lg:grid-cols-1 grid-flow-row gap-y-4">
        <div>
          <div className="text-sm mb-1 lg:text-pp-grey50">
            Sustainability credentials
          </div>
          <Toggle
            onClick={() => {
              setGreenMode(!greenMode)
              return greenMode
            }}
            init={true}
            activeClass="text-sustainabilityTint"
          />
        </div>
        {/* <div>
              <div className="text-sm mb-1 lg:text-pp-grey50">Associated projects</div>
              <Toggle 
              onClick={() => {
                setAssocProducts(!assocProducts)
                return assocProducts
              }} 
              activeClass="text-filterTint" 
              />
          </div> */}
      </div>
      <div className="mt-6">
        <div class="text-sm mb-1 lg:text-pp-grey50">Industry</div>
        <ul className="filter-tag-list light mt-2">
          {filterIndustries.map((item) => {
            return (
              <li>
                <LabelFilter
                  text={item.title}
                  active={filterExists(item.title, 'industry')}
                  onClick={() =>
                    toggleFilter(item.title, 'industry', (project) => {
                      var projectIsShown = false
                      project.industries.map((projectIndustry) =>
                        projectIndustry.title.trim() == item.title.trim()
                          ? (projectIsShown = true)
                          : (projectIsShown = false)
                      )
                      return projectIsShown
                    })
                  }
                />
              </li>
            )
          })}
        </ul>
      </div>
      <div className="mt-6">
        <div className="text-sm mb-1 lg:text-pp-grey50">Features</div>
        <ul className="filter-tag-list light mt-2">
          {filterFeatures.map((item) => (
            <li>
              <LabelFilter
                text={item.title}
                active={filterExists(item.title, 'feature')}
                onClick={() =>
                  toggleFilter(item.title, 'feature', (project) => {
                    var projectIsShown = false
                    project.features.map((projectFeature) =>
                      projectFeature.title === item.title
                        ? (projectIsShown = true)
                        : (projectIsShown = false)
                    )
                    return projectIsShown
                  })
                }
              />
            </li>
          ))}
        </ul>
      </div>
      {showFilterCta && ctaButtonText && ctaText && ctaForm && (
        <div className="mt-6">
          <p>{ctaText}</p>
          <button
            type="button"
            className="mt-2 btn-rnd-sm"
            onClick={() => {
              setGlobalForm(ctaForm)
              setFormIsActive(!formIsActive)
            }}
          >
            {ctaButtonText}
          </button>
        </div>
      )}
    </>
  )
}

export default PackagingFilter
