import React from 'react'
import { GatsbyImage } from "gatsby-plugin-image"

//Swiper
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, EffectFade } from 'swiper';
import 'swiper/css';
import 'swiper/css/autoplay';
import 'swiper/css/effect-fade';

const ProductCardGallery = ({ cardImages }) => {
  return (
    <Swiper 
        className="prod-card-gallery group"
        modules={[EffectFade, Navigation]}                               
        spaceBetween={0}
        slidesPerView={1}    
        mousewheel={false}
        loop={true}
        speed={800}
        effect={"fade"}
        navigation={{
            prevEl: '.gallery-prev',
            nextEl: '.gallery-next',
          }}
    >
        {cardImages.map((image) => (
        <SwiperSlide key={image._key}>
            <GatsbyImage          
                image={image.asset.gatsbyImageData} 
                alt={image.asset.alt} 
                className="w-full aspect-square object-cover"
            />
        </SwiperSlide>
        ))}           
        <div className="gallery-prev cursor-pointer hover:opacity-80 trans-std absolute z-10 left-0 px-1 top-0 bottom-0 opacity-0 lg:group-hover:opacity-100 fill-white hidden items-center mix-blend-difference">
            <svg className="w-8 h-8 stroke-current transition duration-300 xl:hover:scale-110" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M11 3L7 9L11 15"/></svg>
        </div>
        <div className="gallery-next cursor-pointer hover:opacity-60 trans-std absolute z-10 right-0 px-1 top-0 bottom-0 transform opacity-0 lg:group-hover:opacity-100 fill-white flex items-center mix-blend-difference">        
        
            <svg className="w-5 h-5 stroke-current transition duration-300 xl:hover:scale-125" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/>
            </svg>            
        </div>                       
    </Swiper>  
  )
}

export default ProductCardGallery