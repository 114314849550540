function isShownByIndustry(project, filters) {
  const industryFilters = filters.filter((filter) => filter.group === "industry");
  if (!industryFilters.length) return true;    
  return industryFilters.some((filter) => filter.fnc(project));
}

function isShownByCategory(project, filters) {
  const categoryFilters = filters.filter((filter) => filter.group === "category");
  if (!categoryFilters.length) return true;    
  return categoryFilters.some((filter) => filter.fnc(project));
}
  
function isShownByFeature(project, filters) {
  const featureFilters = filters.filter((filter) => filter.group === "feature");
  if (!featureFilters.length) return true;    
  return featureFilters.some((filter) => filter.fnc(project));
}
  
export function applyPackagingFilters(projects, filters) {    
  return projects.edges.filter((project) => {
    const showByIndustry = isShownByIndustry(project.node, filters);
    const showByCategory = isShownByCategory(project.node, filters);
    const showByFeature = isShownByFeature(project.node, filters);
    //console.log(showByIndustry, showByCategory, showByFeature);
    return  showByCategory && showByFeature && showByIndustry;
  });
}

