import React, { useState, useEffect } from 'react'
import { Link, navigate } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import ProductCardGallery from './productCardGallery'
import { useSiteState } from '../../lib/siteContext'

const ProductCard = ({
    title,
    slug,
    imageHero,
    sustainabilityOptions,
    certificationOptions,
    enableBuilder,
    basePrice,
    fromPrice,
    categories,
    features,
    showPrice,
    greenMode = false,
    collab,
    cardImages,
    industries,
    builderLinkOverrideHandler = false
}) => {

    const { user, cartProject } = useSiteState();

    const [projectEmail, setProjectEmail] = useState(false)

    useEffect(() => {
        const emailRaw = getProjectEmail();
        if (emailRaw) {
            setProjectEmail(emailRaw);
        }
    }, [])

    const getProjectEmail = () => {
        const emailRaw = localStorage.getItem('projectEmail');
        if (emailRaw) {
            return (JSON.parse(emailRaw));
        } else {
            return false;
        }
    }


    const handleNavigate = (route) => {
        if (builderLinkOverrideHandler && !getProjectEmail()) {
            builderLinkOverrideHandler(route)
        } else {
            navigate(route)
        }
    }

    // if (user !== null && cartProject !== null) {
    //     navigate(`/builder/${slug.current}`)      
    //   } 
    //   if (user !== null && cartProject === null) {
    //     setShowAuthModal(true)
    //   }   
    //   if (user === null && cartProject === null) {
    //     setShowAuthModal(true)
    //   }   
    return (
        <div className="product_card">
            <Link to={`/packaging/${slug.current}`} className="block trans-std overflow-hidden mb-2 xl:hover:rounded-card relative">
                <div className={`z-10 absolute top-2 left-2 hidden md:flex md:flex-col md:items-start ${!greenMode && 'opacity-0 pointer-events-none'}`}>
                    {sustainabilityOptions.map((item) => (
                        <div className="feature_icon p-2 border border-sustainabilityTint rounded-full mb-2 text-sm flex text-pp-black overflow-hidden">
                            {item.icon && <div className="fill-sustainabilityTint" dangerouslySetInnerHTML={{ __html: item.icon }} />}
                            <span className="text text-sustainabilityTint"> {item.title}</span>
                        </div>
                    ))}
                    {certificationOptions.map((item) => (
                        <>
                            {item.icon && (
                                <div className="feature_icon p-2 border border-pp-grey70 rounded-full mb-2 flex text-sm text-pp-black overflow-hidden">
                                    {item.icon && <div className="fill-pp-grey70" dangerouslySetInnerHTML={{ __html: item.icon }} />}
                                    <span className="text text-pp-grey70"> {item.title}</span>
                                </div>
                            )}
                        </>
                    ))}
                </div>
                {collab && (
                    <span className="block z-10 absolute top-2 right-2 md:top-3 md:right-3 py-1.5 px-3 bg-pp-grey30 text-white rounded-full text-xxs md:text-base">{collab}</span>
                )}

                <div className="w-full aspect-square relative">
                    {cardImages && cardImages[0] ? (
                        <ProductCardGallery cardImages={cardImages} />
                    ) :
                        imageHero && (
                            <GatsbyImage
                                image={imageHero.asset.gatsbyImageData}
                                alt={imageHero.asset.alt ? imageHero.asset.alt : " "}
                                className="w-full aspect-square object-cover"
                            />
                        )
                    }
                </div>


            </Link>



            <div className="grid grid-cols-1 grid-flow-row md:grid-cols-2 gap-gutter text-sm items-baseline">
                <div>
                    <Link to={`/packaging/${slug.current}`} className="hover:underline">
                        <div className="mr-2 py-1">{title && title}</div>
                        {showPrice && fromPrice && fromPrice !== 0 && <div className="mt-1">From NZD ${fromPrice}<span className="ml-1 opacity-60">per unit</span></div>}
                    </Link>

                    {!showPrice && <span className="hidden xl:block opacity-60">Sizing & print options</span>}
                </div>

                <div className="flex flex-col items-start md:items-end opacity-60">
                    {enableBuilder &&
                        <button
                            onClick={() => handleNavigate(`/builder/${slug.current}`)}
                            className="py-1 px-2 border border-doc-text trans-fast bg-doc-text text-white rounded-full mb-1 xl:hover:bg-transparent xl:hover:text-current trans-std text-xxs md:text-base"
                        >
                            Customise
                        </button>
                    }
                    {/* <p>{industries.map((cat) => (
                        <div>{cat.title}</div>
                    ))}</p> */}
                    {showPrice && <span className="hidden xl:block">Sizing & print options</span>}
                </div>
            </div>
        </div>
    )
}

export default ProductCard