import React from 'react'
import { useState } from "react";
import { motion } from "framer-motion";


const spring = {
    type: "spring",
    stiffness: 700,
    damping: 30
  };

const Toggle = ({ onClick, activeClass, init = false }) => {

    const [isOn, setIsOn] = useState(init);

    function handleClick() { 
      setIsOn(!isOn)
      onClick()
    }

    function handleKeyDown(e) {
      if (e.keyCode === 13) {
        handleClick();
      }
    }
    
    
  return (
    <div 
    onClick={handleClick}
    onKeyDown={handleKeyDown}          
    role="button"
    tabIndex="0"                 
    className={`toggle ${isOn && activeClass}`} data-isOn={isOn}>
      <motion.div className="handle" layout transition={spring} />
    </div>
  )
}

export default Toggle