import React, { useState } from 'react'
import { motion, AnimatePresence } from 'framer-motion';

// Components
import PackagingIntroExpand from "./packagingIntroExpand"
import { PortableTextBlock } from "../ui//portableTextBlock"

const accordionReveal = {
    open: { opacity: 1, height: 'auto' },
    closed: { opacity: 0, height: '0px' },
};

const PackagingIntro = ({ description, introText, learnMore, learnBtnHide, learnBtnShow }) => {

    const [sectionIsOpen, setSectionIsOpen] = useState(false)
  return (
    <div className="theme-grey-20 z-20 grid-std gap-y-6 grid-flow-row py-10 lg:pb-20 text-white">
        <div className="col-start-2 col-end-8 lg:col-end-17">
            <motion.h1
            className="text-lg lg:text-xl"
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{ delay: 0.1, duration: 0.5 }}
            viewport={{ once: true }}
            >{introText && introText}</motion.h1>
        </div>
        <motion.div
            className="col-start-2 col-end-8 lg:col-start-17 lg:col-end-26 prose"
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{ delay: 0.1, duration: 0.5 }}
            viewport={{ once: true }}
        >{description && <PortableTextBlock text={description} />}</motion.div>
        <div className="col-start-2 col-end-8 lg:col-end-26 overflow-hidden">
            <AnimatePresence exitBeforeEnter initial={false}>
                {sectionIsOpen && (
                    <motion.div
                        className="overflow-hidden lg:grid lg:grid-cols-12"
                        variants={accordionReveal}
                        initial="closed"
                        animate="open"
                        exit="closed"
                        transition={{
                            type: "spring",
                            mass: 0.35,
                            stiffness: 75,
                        }}
                    >
                    <PackagingIntroExpand {...learnMore} />
                    </motion.div>
                )}        
            </AnimatePresence>    
        </div>
             
        <div className="col-start-2 col-end-8 lg:col-end-26 border-b border-pp-grey40 pb-4">
            <div 
            onClick={() => setSectionIsOpen(!sectionIsOpen)}
            className={`group trans-std cursor-pointer text-md lg:text-xl opacity-50 xl:hover:opacity-100 flex justify-between`}
            >
                <span>{sectionIsOpen ? learnBtnHide : learnBtnShow}</span>
                <span className="trans-std opacity-0 xl:group-hover:opacity-100 scale-0 xl:group-hover:scale-100">{sectionIsOpen ? '-' : '+'}</span>
            </div>
        </div>       
    </div>
  )
}

export default PackagingIntro