import React from 'react'

import AccordionSection from "../ui/accordionSection"
import { PortableTextBlock } from "../ui/portableTextBlock"

const PackagingIntroExpand = ({ description, rowContent }) => {
    return (
        <>
         <div className="lg:col-start-1 lg:col-end-4 prose max-w-none">
                {description && <PortableTextBlock text={description} />}
            </div>
            <div className="lg:col-start-6 lg:col-end-13 grid grid-cols-1 grid-flow-row gap-y-2">
                {rowContent.map((content) => {
                    return (
                        <AccordionSection {...content} />
                    )
                })}
            </div>    
        </>
    )
}

export default PackagingIntroExpand