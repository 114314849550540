import React, { useState } from 'react'
import { PortableTextBlock } from "../ui/portableTextBlock"
import { AnimatePresence, motion } from 'framer-motion'

//Animation
import { accordionVariants } from '../../lib/animation';

const AccordionSection = ({ className, heading, subheading, text, isOpen = false }) => {

  const [isOpened, setIsOpened] = useState(false);

  return (
    <div className={`border-b border-current overflow-hidden ${className}`}>
    <button 
      className="w-full flex justify-between items-baseline py-2"
      onClick={() => setIsOpened(!isOpened)}
    >
          <span>{heading}{subheading && <span className="text-sm ml-4">{subheading}</span>}</span>
          <span>{isOpened ? '-' : '+'}</span>
        </button>
        
        <AnimatePresence exitBeforeEnter>
          {isOpened && (
            <motion.div
              variants={accordionVariants}
              initial="closed"
              animate="open"
              exit="closed"
            >
              <div className="py-4">
              {text && <PortableTextBlock className="prose measure" text={text} />}
              </div>
            </motion.div>
          )}
          </AnimatePresence>       
    </div>
  )
}

export default AccordionSection