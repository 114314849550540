import React, { useEffect, useState } from 'react'
import { useWindowWidth } from '@react-hook/window-size'
import { motion, AnimatePresence } from 'framer-motion'
import { graphql } from 'gatsby'

//State
import { applyPackagingFilters } from '../utils/packagingFiltering'
import { useSiteState } from '../lib/siteContext'

// Components
import ProductsLayoutToggle from '../components/products/productsLayoutToggle'
import ProductBuilderModal from '../components/products/productBuilderModal'
import ShowFilterToggle from '../components/projects/showFilterToggle'
import PackagingBanner from '../components/components/packagingBanner'
import PackagingFilter from '../components/products/packagingFilter'
import PackagingIntro from '../components/products/packagingIntro'
import ProductCard from '../components/products/productCard'
import ActiveFilters from '../components/ui/activeFilters'
import Seo from '../components/global/seo'
import Layout from '../components/layout'

//Animation
import { filterDrawReveal, packagingListVars } from '../lib/animation'

const PackagingPage = ({
	data: { pageData, productItems, filtersIndustries, filterCategories },
}) => {
	const {
		bannerImage,
		description,
		introText,
		headerTheme,
		pageTheme,
		footerTheme,
		headerOverlaps,
		myPorterCta,
		seo,
		learnMore,
		learnBtnShow,
		learnBtnHide,
		filterFeatures,
		filterIndustries,
		showFilterCta,
		ctaText,
		ctaButtonText,
		ctaForm,
		// slug
	} = pageData

	const {
		setMyPorter,
		setFooterHidden,
		setHeaderDark,
		setPageBackground,
		setFooterTheme,
		setFooterForms,
		productGridLarge,
		setProductGridLarge,
		setShowingBriefUs,

		showAuthModal,
		setShowAuthModal,
	} = useSiteState()

	useEffect(() => {
		if (pageTheme.color.value) {
			document.documentElement.setAttribute(
				'data-theme',
				`theme-${pageTheme.color.title}`
			)
			document.documentElement.style.setProperty(
				`--doc-bg`,
				pageTheme.color.value
			)
			setPageBackground(pageTheme.color.value)
		}
		setMyPorter(myPorterCta === true ? true : false)
		setFooterForms(false)
		setHeaderDark(headerTheme.color.title === 'Black' ? true : false)
		setFooterTheme(
			footerTheme.color.title ? footerTheme.color.title : 'theme-none'
		)
		setFooterHidden(false)
		setShowingBriefUs(false)
	}, [
		pageTheme,
		setFooterHidden,
		setPageBackground,
		setMyPorter,
		myPorterCta,
		headerTheme,
		setHeaderDark,
		footerTheme,
		setFooterTheme,
		setFooterForms,
	])

	useEffect(() => {
		document.documentElement.style.setProperty(`--doc-bg`, '#141414')
	}, [])

	const [greenMode, setGreenMode] = useState(true)
	const [assocProducts, setAssocProducts] = useState(false)
	const [filters, setFilters] = useState([])
	const [filtersOpen, setFiltersOpen] = useState(false)
	const windowWidth = useWindowWidth({ wait: 300, initialWidth: 1024 })
	const [slug, setSlug] = useState(false)
	let shownProducts = applyPackagingFilters(productItems, filters)

	const handleBuilderLink = (e) => {
		console.log('Handling Builder Link')
		console.log(e)
		setSlug({ current: e.split('/').at(-1) })
		setShowAuthModal(true)
	}

	return (
		<Layout>
			{seo && <Seo {...seo} hidden={true} />}

			{!headerOverlaps && <div className="block w-full h-header"></div>}

			{bannerImage && <PackagingBanner {...{ bannerImage }} />}

			<PackagingIntro
				{...{ learnBtnShow, learnBtnHide, description, introText, learnMore }}
			/>

			<section id="packaging_container" className="theme-grey-20">
				<AnimatePresence exitBeforeEnter>
					{/* {((filtersOpen && windowWidth < 1024) || (windowWidth > 1023)) && ( */}

					{/* desktop */}
					<motion.div
						className="packaging_filter desktop "
						variants={filterDrawReveal}
						initial="closed"
						animate="open"
						exit="closed"
					>
						<PackagingFilter
							{...{
								amountTotal: productItems.edges.length,
								amountVisible: shownProducts.length,
								filtersIndustries,
								filterIndustries,
								filterCategories,
								setAssocProducts,
								filterFeatures,
								setFiltersOpen,
								assocProducts,
								showFilterCta,
								ctaButtonText,
								setGreenMode,
								setFilters,
								greenMode,
								ctaText,
								ctaForm,
								filters,
							}}
						/>
					</motion.div>

					{/* mobile  */}
					{filtersOpen && (
						<motion.div
							className="mobile packaging_filter"
							variants={filterDrawReveal}
							initial="closed"
							animate="open"
							exit="closed"
						>
							<PackagingFilter
								{...{
									amountTotal: productItems.edges.length,
									amountVisible: shownProducts.length,
									filterIndustries,
									filterCategories,
									setAssocProducts,
									filterFeatures,
									setFiltersOpen,
									assocProducts,
									showFilterCta,
									ctaButtonText,
									setGreenMode,
									setFilters,
									greenMode,
									ctaText,
									ctaForm,
									filters,
								}}
							/>
						</motion.div>
					)}
				</AnimatePresence>

				<div className="packaging_topbar">
					<ShowFilterToggle
						className="col-span-4 flex justify-start text-white lg:hidden"
						setFiltersOpen={setFiltersOpen}
						filtersOpen={filtersOpen}
					/>

					<div className="row-start-2 lg:row-start-1 col-span-full lg:col-span-3">
						<div className="flex flex-col">
							<span className="hidden lg:block text-pp-grey50 mb-1 text-sm">
								Filters
							</span>
							<span>
								{filters.length >= 1 ? (
									<ActiveFilters filters={filters} setFilters={setFilters} />
								) : (
									<span className="text-pp-grey30 text-sm">
										No filters selected
									</span>
								)}
							</span>
						</div>
					</div>

					<ProductsLayoutToggle
						className="col-span-4 lg:col-span-9 flex justify-end gap-x-4 text-white"
						{...{ setProductGridLarge, productGridLarge }}
					/>
				</div>

				<AnimatePresence exitBeforeEnter>
					<motion.div
						id="packaging_main"
						variants={packagingListVars}
						initial="closed"
						animate="open"
						exit="closed"
						key={shownProducts}
						className={
							productGridLarge
								? 'grid-cols-1 lg:grid-cols-2'
								: 'grid-cols-2 lg:grid-cols-3'
						}
					>
						{shownProducts.map((product) => (
							<ProductCard
								greenMode={greenMode}
								builderLinkOverrideHandler={handleBuilderLink}
								{...product.node}
							/>
						))}
					</motion.div>
				</AnimatePresence>
			</section>
			<ProductBuilderModal slug={slug} />
		</Layout>
	)
}

export default PackagingPage

export const pageQuery = graphql`
	query packagingQuery {
		filtersFeatures: allSanityProductFeature(sort: { fields: title }) {
			edges {
				node {
					title
				}
			}
		}
		filtersIndustries: allSanityIndustry(sort: { fields: title }) {
			edges {
				node {
					title
					slug {
						current
					}
				}
			}
		}
		filterCategories: allSanityCategory(sort: { fields: title }) {
			edges {
				node {
					title
					slug {
						current
					}
				}
			}
		}
		pageData: sanityPackaging {
			seo {
				...SEO
			}
			title
			pageTheme {
				color {
					value
					title
				}
			}
			introText
			description: _rawDescription(resolveReferences: { maxDepth: 10 })
			bannerImage {
				asset {
					gatsbyImageData
				}
			}
			learnBtnShow
			learnBtnHide
			learnMore {
				description: _rawDescription(resolveReferences: { maxDepth: 10 })
				rowContent {
					isOpen
					heading
					subheading
					text: _rawContent(resolveReferences: { maxDepth: 10 })
				}
			}
			showFilterCta
			ctaText
			ctaButtonText
			ctaForm {
				title
				submitButton
				textSuccess: _rawTextSuccess(resolveReferences: { maxDepth: 10 })
				text: _rawText(resolveReferences: { maxDepth: 10 })
				simpleOption
				simpleOptionText
				fullOptionText
				inputsSimple {
					items {
						... on SanityFormsInput {
							_key
							_type
							width
							type
							title
							required
							placeholder
						}
						... on SanityFormsSelect {
							_key
							_type
							width
							values
							title
							required
							placeholder
						}
						... on SanityFormsTextarea {
							_key
							_type
							width
							title
							required
							placeholder
						}
					}
				}
				inputs {
					items {
						... on SanityFormsInput {
							_key
							_type
							width
							type
							title
							required
							placeholder
						}
						... on SanityFormsSelect {
							_key
							_type
							width
							values
							title
							required
							placeholder
						}
						... on SanityFormsTextarea {
							_key
							_type
							width
							title
							required
							placeholder
						}
					}
				}
			}
			filterFeatures {
				title
			}
			filterIndustries {
				title
				slug {
					current
				}
			}
			headerTheme {
				color {
					value
					title
				}
			}
			headerOverlaps
			footerTheme {
				color {
					value
					title
				}
			}
		}
		productItems: allSanityProduct {
			edges {
				node {
					title
					slug {
						current
					}
					collab
					basePrice
					fromPrice
					showPrice
					enableBuilder
					cardImages {
						asset {
							gatsbyImageData
						}
					}
					sustainabilityOptions {
						title
						icon
					}
					certificationOptions {
						title
						icon
					}
					categories {
						title
						slug {
							current
						}
					}
					features {
						title
					}
					industries {
						title
					}
					description: _rawResourceDescription(
						resolveReferences: { maxDepth: 10 }
					)
					resources {
						name
						file {
							asset {
								url
							}
							_type
						}
					}
					imageHero {
						asset {
							gatsbyImageData
						}
					}
				}
			}
		}
	}
`
