import React from 'react'
import { useWindowWidth } from '@react-hook/window-size'

//SVG
import ThreeColIcon from '../../svg/nineItemGrid.svg';
import TwoColIcon from '../../svg/fourItemGrid.svg';
import OneColIcon from '../../svg/projectListIcon.svg';

const ProductsLayoutToggle = ({ className, productGridLarge, setProductGridLarge }) => {
    
    const windowWidth = useWindowWidth()

  return (
    <div className={className}>        
        <div
            role="button" 
            tabIndex="0"
            className={`trans-std ${productGridLarge ? 'opacity-50 xl:hover:opacity-100 xl:hover:scale-110' : 'pointer-events-none'}`}
            onClick={() => setProductGridLarge(false)} 
        >
            {windowWidth > 1023 ? (
                <ThreeColIcon />
            ) : (
                <TwoColIcon />
            )}
            
        </div>
        <div 
            role="button" 
            tabIndex="0"
            className={`trans-std ${!productGridLarge ? 'opacity-50 xl:hover:opacity-100 xl:hover:scale-110' : 'pointer-events-none'}`}
            onClick={() => setProductGridLarge(true)} 
        >
            {windowWidth > 1023 ? (
                <TwoColIcon />
            ) : (
                <OneColIcon />
            )}            
        </div>
    </div>
  )
}

export default ProductsLayoutToggle